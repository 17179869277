<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
  >
    <a-divider orientation="left">
      <b>消息内容
      </b>
    </a-divider>
    <div v-html="form.newsContent"></div>
  </a-drawer>
</template>

<script>
import { getMessage, addMessage, updateMessage } from '@/api/project/message'
import upImgModal from '@/components/upImgModal'
import Editor from '@/components/Editor'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    upImgModal,
    Editor
  },
  data () {
    return {
      previewVisible: false,
      fileList: [
      ],
      agentList: [],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: 0,
        newsTitle: '',
        newsSketch: undefined,
        fileUrl: '',
        newsContent: ''
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        newsTitle: [
          { required: true, message: '请输入消息标题', trigger: 'blur' }
        ],
        fileUrl: [
          { required: true, message: '请上传图片', trigger: 'blur' }
        ],
        newsSketch: [
          { required: true, message: '请输入消息简述', trigger: 'blur' }
        ],
        newsContent: [
          { required: true, message: '请输入消息内容', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {

  },
  computed: {
  },
  watch: {
  },

  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    setImg (url) {
      console.log(url, '大大大大大====')
      this.form.fileUrl = url
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: 0,
        title: '',
        genusId: undefined,
        fileUrl: '',
        description: '',
        sort: ''
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMessage({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            updateMessage(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addMessage(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ant-upload-preview {
  position: relative;
  width: 100px;
  height: 100px;

  .upload-icon {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 1.4rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mask {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }

    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: #d6d6d6;
    }
  }

  img,
  .mask {
    width: 100%;
    max-width: 180px;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
  }
}
</style>
@/api/valve/deviceType
